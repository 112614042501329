import { useQuery } from "@apollo/client";
import { GridCellProps } from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse, TTicketVolumeRevision } from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

type Response = GqlResponse<TTicketVolumeRevision[], "volumesOfTicketAtRevision">;

const query = loader("../../ticketing-graphql/volumesOfTicketAtRevision.graphql");

const LastModifiedByCell = (props: GridCellProps) => {
  const item = props.dataItem;
  const displayName = [item.lastModifiedBy.lastName, item.lastModifiedBy.firstName]
    .filter(Boolean)
    .join(", ");
  return <td title={item.lastModifiedBy.id}>{displayName}</td>;
};

const transformData = (ticketRevisionVolumes: TTicketVolumeRevision[]) => {
  return ticketRevisionVolumes?.map(trm => ({
    ...trm,
    lastModifiedDate: new Date(trm.lastModifiedDate + "Z")
  }));
};

type RevisionsTicketVolumesProps = {
  ticketId: string;
  revisionNumber: string;
};

/***
 *
 */
const RevisionsTicketVolumes = ({ ticketId, revisionNumber }: RevisionsTicketVolumesProps) => {
  const [revisions, setRevisions] = useState<TTicketVolumeRevision[]>();
  const { loading, error } = useQuery<Response>(query, {
    fetchPolicy: "no-cache",
    variables: { ticketId, revisionNumber },
    onCompleted: data => setRevisions(transformData(data.volumesOfTicketAtRevision))
  });

  const childData = [
    { title: "Volume Id", field: "id" },
    {
      title: "Net Volume",
      field: "netVolume",
      format: "{0:n4}"
    },
    {
      title: "Gross Volume",
      field: "grossVolume",
      format: "{0:n4}"
    },
    {
      title: "Unit of Measure",
      field: "unitOfMeasure.name"
    },
    { title: "Temperature", field: "temperature" },
    {
      title: "Temperature UOM",
      field: "temperatureUnitOfMeasure"
    },
    {
      title: "Updated At",
      field: "lastModifiedDate",
      width: "140px",
      format: "{0:MM/dd/yy HH:mm:ss}"
    },
    {
      title: "Updated By",
      field: "lastUpdateUser",
      cell: LastModifiedByCell
    }
  ];

  return (
    <RevisionsGridWrapper
      loading={loading}
      error={error}
      dataContent={revisions}
      columns={childData}
    />
  );
};

export default RevisionsTicketVolumes;
