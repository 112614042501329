import {
  Picklists,
  TCounterParty,
  TMovement,
  TMovementGroup,
  TShipCode,
  TTicket,
  TTicketInput,
  TTicketInputValidationData,
  TTicketValidationResult,
  TUserQueryCode,
  TValidationsConfig,
  TVolumeInput
} from "ticketing/ticketing.types";
import {
  CELSIUS_TEMPERATURE_MEASURE,
  MODE_OF_TRANSPORT_RAIL,
  STCAN_DEFAULT_CELSIUS_TEMPERATURE,
  equalsIgnoreCase,
  finUomTypeNameForStorage,
  fromISODateTimeString,
  hasCreditRedLine,
  hasMassRow,
  hasVolumeRow,
  isActualizedByInfoSet,
  isAutoActualizeInfoSet,
  isCancelledMovement,
  isDestinationRule11Incoterm,
  isInventoryGainLoss,
  isMassMeasureHavingZeroVolume,
  isMotRailOrTruck,
  isMovementActualizedByGSAP,
  isMovementDaily,
  isMovementHavingActivityTypeBackToBack,
  isMovementNotHavingMatchingUomOnBothDeals,
  isMovementPhysToPhys,
  isMovementPhysicalToStorage,
  isMovementStorageToPhysical,
  isMovementStorageToStorage,
  isMovementTansitToStorageOrStorageToTransit,
  isMovementTransitOrStorage,
  isMovementTransitToPhysOrPhysToTransit,
  isNonTransitMovement,
  isPhysicalDeal,
  isPlannedMovement,
  isRailOrTruck,
  isRins,
  isStorageDeal,
  isTicketAndFinUomNameNotMatching,
  isTicketAndFinUomNotMatching,
  isTicketAndFinUomTypeNameNotMatching,
  isTransitMovement,
  isTruckOrRail,
  isValidPageRange,
  isVolumeMeasureHavingZeroVolume,
  mapTempUOM,
  maxDates,
  minDates,
  requireVolAndMassUnit,
  skipValidationForInterBook,
  skipValidationForMOTsOtherThanTruckAndRail,
  skipValidationForSpecificLocations,
  skipValidationForSpecificProductGroups
} from "ticketing/utils";

const configIsInActive = (config: TValidationsConfig, group: TMovementGroup | null) =>
  config.mackActive === 0 ||
  (isPlannedMovement(group) && config.runForPlannedNomIndicator === 0) ||
  (isTransitMovement(group) && config.runForTransitIndicator === 0) ||
  (isNonTransitMovement(group) && config.runForNonTransitIndicator === 0) ||
  (isCancelledMovement(group) && config.runForCancelledNomIndicator === 0);

const getValidationType = (validationType: string | undefined): boolean | undefined => {
  if (validationType) {
    return equalsIgnoreCase(validationType, "error");
  }
  return undefined;
};

const shouldApplyConfigToMovement = (
  config: TValidationsConfig,
  group: TMovementGroup | null
) => {
  return (
    config.legalEntityId === "0" ||
    group?.movements?.some(m =>
      equalsIgnoreCase(m.internalLegalEntity?.id, config.legalEntity?.id)
    )
  );
};
export const skipValidation = (
  validationConfig: TValidationsConfig[],
  movementGroup: TMovementGroup | null,
  code: string
): boolean => {
  //skip validation if its inactive or doesn't apply to this nom's legal entity
  return !validationConfig.some(
    config =>
      config.code === code &&
      !configIsInActive(config, movementGroup) &&
      shouldApplyConfigToMovement(config, movementGroup)
  );
};

const validateTicketNumber = function (this: TTicketInputValidationData) {
  if (!this.ticket.ticketNumber) {
    return {
      error: true,
      message: "Invalid Ticket Number"
    };
  } else {
    return undefined;
  }
};

const validateTicketDate = function (this: TTicketInputValidationData) {
  if (
    !this.ticket.startDate ||
    !(this.ticket.startDate instanceof Date) ||
    isNaN(this.ticket.startDate.getTime())
  ) {
    return {
      error: true,
      message: "Invalid Ticket date"
    };
  } else {
    return undefined;
  }
};

const validateCarrier = function (this: TTicketInputValidationData) {
  if (isTruckOrRail(this.ticket) && !this.ticket.carrier) {
    return {
      error: true,
      message: "Carrier is required when MOT is Truck or Rail"
    };
  } else {
    return undefined;
  }
};

const validateCarrierScacCode = function (this: TTicketInputValidationData) {
  if (this.ticket.carrier && !this.ticket.carrierScacCode?.trim()) {
    return {
      error: true,
      message: "Carrier SCAC Code cannot be blank"
    };
  } else {
    return undefined;
  }
};

const validatePageRange = function (this: TTicketInputValidationData) {
  if (
    !!this.numberOfPages &&
    (!this.ticket.pageNumbers || !isValidPageRange(this.ticket.pageNumbers, this.numberOfPages))
  ) {
    return {
      error: true,
      message: "Invalid page range"
    };
  }
  return undefined;
};

const isTemperatureDataMissing = (volume: TVolumeInput) =>
  !volume.temperatureUnitOfMeasure || !volume.temperature;

const validateVolumes = function (this: TTicketInputValidationData) {
  if (
    this.ticket.volumes.find(
      v =>
        !v.unitOfMeasure ||
        !v.netVolume ||
        isTemperatureDataMissing(v) ||
        (isMotRailOrTruck(this.ticket.modeOfTransport?.name) && !v.grossVolume)
    )
  ) {
    return {
      error: true,
      message: "Invalid Volume Unit of Measure/Temperature/Net Volume/Gross Volume"
    };
  } else {
    return undefined;
  }
};

const validateDuplicateVolumes = function (this: TTicketInputValidationData) {
  if (
    this.ticket.volumes.find((v, vIndex) =>
      this.ticket.volumes.find(
        (v2, v2Index) =>
          v.unitOfMeasure?.id === v2.unitOfMeasure?.id &&
          v.temperatureUnitOfMeasure?.id === v2.temperatureUnitOfMeasure?.id &&
          v.temperature === v2.temperature &&
          vIndex !== v2Index
      )
    )
  ) {
    return {
      error: true,
      message: "Duplicate volumes"
    };
  } else {
    return undefined;
  }
};

const validateRailcars = function (this: TTicketInputValidationData) {
  if (
    equalsIgnoreCase(this.ticket.modeOfTransport?.name, MODE_OF_TRANSPORT_RAIL) &&
    !this.ticket?.railcars?.trim()
  ) {
    return {
      error: true,
      message: "Mode Of Transport `Rail` requires Railcars"
    };
  } else {
    return undefined;
  }
};

const validateStorageDates = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (!this.movementGroup) {
    return undefined;
  }

  const storageDeals = this.movementGroup.movements
    .flatMap(m => [m.recDeal, m.delDeal].filter(Boolean))
    .filter(d => isStorageDeal(d))
    .flatMap(d => ({
      startDate: d.commitment?.startDate,
      endDate: d.commitment?.endDate
    }));

  const [contractStartDate, contractEndDate] = storageDeals.reduce<(Date | undefined)[]>(
    (acc, val) => {
      acc[0] = minDates(acc[0], val.startDate as Date | undefined);
      acc[1] = maxDates(acc[1], val.endDate as Date | undefined);
      return acc;
    },
    []
  );

  if (
    (contractStartDate && this.ticket.startDate && contractStartDate > this.ticket.startDate) ||
    (contractEndDate && this.ticket.startDate && contractEndDate < this.ticket.startDate)
  ) {
    return {
      error: true,
      message: "Ticket is Outside of storage contract dates" //~~ for 2622751 use similar logic
    };
  }
};

const validatePlannedMovement = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (isPlannedMovement(this.movementGroup)) {
    return { error: true, message: "Movement status is PLANNED" };
  }
};

const validateAutoActualized = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (isAutoActualizeInfoSet(this.movementGroup)) {
    return { error: true, message: "Movement is to Auto Actualize with in Endur" };
  }
};

const validateActualizedBySet = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (isActualizedByInfoSet(this.movementGroup)) {
    return { error: true, message: "Actualized by has been set by Endur" };
  }
};

const validateActualizedExternally = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (this.movementGroup?.movements.find(m => m.isActualizedExternally)) {
    return { error: true, message: "Movement is actualized externally" };
  }
};

const validateBatchTransitCompleted = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (this.movementGroup?.movements.find(m => m.batch?.transitComplete)) {
    return { error: true, message: "Batch transit complete is true" };
  }
};

const validateFacility = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup &&
    !this.movementGroup.movements.find(
      m => m.titleTransferFacility?.id === this.ticket.facility?.id
    )
  ) {
    return {
      warning: true,
      message: "Location does not match"
    };
  }
};

const validateProduct = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup &&
    !this.movementGroup.movements.find(m => m.product?.id === this.ticket.product?.id)
  ) {
    return {
      warning: true,
      message: "Product does not match"
    };
  }
};

const validateLogisticsSystem = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup &&
    !this.movementGroup.movements.find(
      m => m.logisticsSystem?.id === this.ticket.logisticsSystem?.id
    )
  ) {
    return {
      warning: true,
      message: "Logistics System does not match"
    };
  }
};

const validateBatch = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup &&
    !this.movementGroup.movements.find(m => m.batch?.id === this.ticket.batch?.id)
  ) {
    return {
      warning: true,
      message: "Batch does not match"
    };
  }
};

const allHaveGrossVolume = (ticket: TTicketInput): boolean => {
  return ticket.volumes.filter(v => v.grossVolume == null || v.grossVolume <= 0).length === 0;
};

const requiresGrossVolume = (movementGroup: TMovementGroup) =>
  !!movementGroup?.movements.some(
    m =>
      (m.recDeal?.commitment?.requiredGrossVolume ?? false) ||
      (m.delDeal?.commitment?.requiredGrossVolume ?? false)
  );

const validateGrossVolumesForMOT = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup &&
    this.ticket &&
    isRailOrTruck(this.movementGroup) &&
    !allHaveGrossVolume(this.ticket)
  ) {
    return {
      error: true,
      message: "Gross volume is required when the MOT is Truck or Rail"
    };
  }
};

const validateGrossVolumesForGrossPricing = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup &&
    this.ticket &&
    requiresGrossVolume(this.movementGroup) &&
    !allHaveGrossVolume(this.ticket)
  ) {
    return {
      error: true,
      message:
        "Gross Volume is required because pricing for the associated deal(s) are based on the gross quantity"
    };
  }
};

//possible values for requires ship code for are
// STC_CptyUseTicketShipToShipFrom
// CptySpecialHandling
const counterpartyRequiresShipCode = (counterParty: TCounterParty, code: string): boolean => {
  return counterParty?.requiresShipCodeFor?.includes(code) ?? false;
};

const movementRequiresShipToCode = (
  movementGroup: TMovementGroup | null,
  code: string
): boolean => {
  return (
    movementGroup?.movements
      .map(m => m.delDeal)
      .some(d => isPhysicalDeal(d) && counterpartyRequiresShipCode(d.counterParty, code)) ??
    false
  );
};

const movementRequiresShipFromCode = (
  movementGroup: TMovementGroup | null,
  code: string
): boolean => {
  return (
    movementGroup?.movements
      .map(m => m.recDeal)
      .some(d => isPhysicalDeal(d) && counterpartyRequiresShipCode(d.counterParty, code)) ??
    false
  );
};

const validateShipFromCodeForTax = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    movementRequiresShipFromCode(this.movementGroup, "STC_CptyUseTicketShipToShipFrom") &&
    this.ticket.shipFromCode?.id == null
  ) {
    return {
      error: true,
      message: "Ship From is required for receive deal counterparty for tax"
    };
  }
};

const validateShipToCodeForTax = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    movementRequiresShipToCode(this.movementGroup, "STC_CptyUseTicketShipToShipFrom") &&
    this.ticket.shipToCode?.id == null
  ) {
    return {
      error: true,
      message: "Ship To is required for delivery deal counterparty for tax"
    };
  }
};

const validateShipFromCodeForReporting = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    movementRequiresShipFromCode(this.movementGroup, "CptySpecialHandling") &&
    this.ticket.shipFromCode?.id == null
  ) {
    return {
      error: true,
      message: "Ship From is required for receive deal counterparty for reporting"
    };
  }
};

const validateShipToCodeForReporting = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    movementRequiresShipToCode(this.movementGroup, "CptySpecialHandling") &&
    this.ticket.shipToCode?.id == null
  ) {
    return {
      error: true,
      message: "Ship To is required for delivery deal counterparty for reporting"
    };
  }
};

const validateShipFromCodeForInternationalBorder = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup?.movements.map(m => m.recDeal).some(d => isPhysicalDeal(d)) &&
    this.movementGroup?.movements.some(
      m => m.titleTransferFacility?.isInternationalBorder === true
    ) &&
    this.ticket.shipFromCode?.id == null
  ) {
    return {
      error: true,
      message: "Ship From is required for International Border/Waterline Locations"
    };
  }
};

const validateShipToCodeForInternationalBorder = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup?.movements.map(m => m.delDeal).some(d => isPhysicalDeal(d)) &&
    this.movementGroup?.movements.some(
      m => m.titleTransferFacility?.isInternationalBorder === true
    ) &&
    this.ticket.shipToCode?.id == null
  ) {
    return {
      error: true,
      message: "Ship To is required for International Border/Waterline Locations"
    };
  }
};

//Task 2838492 - https://sede-ds-adp.visualstudio.com/MACk/_workitems/edit/2838492
const validateMassVolumeForSuperFundTax = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    (this.movementGroup?.movements.some(m => equalsIgnoreCase(m.product.superFundTax, "Yes")) ||
      equalsIgnoreCase(this.ticket.product?.superFundTax, "Yes")) &&
    !this.ticket.volumes.some(v =>
      equalsIgnoreCase(v.unitOfMeasure?.unitOfMeasureClass.name, "Mass")
    )
  ) {
    return {
      error: true,
      message: 'SuperFund Tax product requires "Mass" unit of measure'
    };
  }
};

const validateVolumeMeasure = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.ticket.volumes.some(v =>
      equalsIgnoreCase(v.unitOfMeasure?.unitOfMeasureClass.name, "Mass")
    ) &&
    !isRins(this.movementGroup) &&
    this.movementGroup?.movements
      .filter(m => m.activityType?.serviceProviderId !== 9)
      .flatMap(m => m.measures.filter(mm => mm.measurementType?.name === "Volume"))
      .some(mm => mm.value <= 0.0)
  ) {
    return {
      warning: true,
      message: "Measure type of Volume is required in order to calculate tax properly"
    };
  }
};

const validateTemperature = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    !this.movementGroup?.movements
      .flatMap(m => m.measures.filter(mm => mm.measurementType?.name === "Temperature"))
      .find(mm =>
        this.ticket.volumes.find(t =>
          equalsIgnoreCase(t.temperatureUnitOfMeasure?.name, mapTempUOM(mm.unitOfMeasure.name))
        )
      )
  ) {
    return {
      error: true,
      message: "Matching Temperature not found"
    };
  }
};

const hasL15Volume = (ticket: TTicketInput): boolean => {
  return !!ticket.volumes.find(
    v =>
      equalsIgnoreCase(v.temperatureUnitOfMeasure?.name, CELSIUS_TEMPERATURE_MEASURE) &&
      v.temperature === STCAN_DEFAULT_CELSIUS_TEMPERATURE &&
      equalsIgnoreCase(v.unitOfMeasure?.unitOfMeasureClass.name, "volume")
  );
};

const validateVolumeMeasureIsGreaterThanZero = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.ticket.volumes.some(v =>
      this.movementGroup?.movements.some(m =>
        showErrorOnVolumeMeasureForPriceUnit(
          m,
          v.unitOfMeasure?.name,
          v.unitOfMeasure?.unitOfMeasureClass?.name
        )
      )
    )
  ) {
    return {
      error: true,
      message:
        "Vol Unit is different than Price Unit, Please Enter a Volume/Mass(Air) in the Measures section"
    };
  }
};

const validateVolumeMeasureUpdated = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.ticket.volumes.some(v =>
      this.movementGroup?.movements.some(m =>
        showWarningForVolumeMeasure(
          m,
          v.unitOfMeasure?.name,
          v.unitOfMeasure?.unitOfMeasureClass?.name
        )
      )
    )
  ) {
    return {
      warning: true,
      message: "Make sure you update your Volume/Mass in the Measures section"
    };
  }
};

const validateCreditRedLine = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (hasCreditRedLine(this.movementGroup)) {
    return {
      error: true,
      message: "Credit Red Line"
    };
  }
};

const validateL15Volume = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (!hasL15Volume(this.ticket) && this.movementGroup) {
    return {
      error: true,
      message: "Missing L15 volume"
    };
  }
};
//if the aggregationType is daily and if the movement already has tickets
//then all other tickets should have the same start date as the first linked ticket PBI2424343
const validateMatchingTicketDate = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    isMovementDaily(this.movementGroup) &&
    this.movementGroup?.movements.some(
      m => m.tickets.length > 0 && isValidForTicketDate(m, this.ticket)
    )
  ) {
    return {
      error: true,
      message: "Dates do not match"
    };
  }
};

const isValidForTicketDate = (m: TMovement, ticket: TTicketInput) =>
  m.tickets[0].startDate &&
  fromISODateTimeString(m.tickets[0].startDate)?.getTime() !== ticket.startDate?.getTime();

//if ship code exists and if the recDeal is physical - it has to be valid for this movement
const validateMatchingShipFromCode = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup?.movements.some(m => isPhysicalDeal(m.recDeal)) &&
    !!this.ticket.shipFromCode?.id &&
    !this.shipFromCodes?.map(s => s.id).includes(this.ticket.shipFromCode.id)
  ) {
    return {
      warning: true,
      message: "Ship From code does not match"
    };
  }
};

const validateDealPricingFormulaAdjustment = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup?.movements.some(
      m =>
        (isPhysicalDeal(m.recDeal) && m.recDeal.hasFormulaPricing) ||
        (isPhysicalDeal(m.delDeal) && m.delDeal.hasFormulaPricing)
    )
  ) {
    return {
      warning: true,
      message: "Make sure to update the measures section with the Pricing Adjustment"
    };
  }
};

//if ship code exists and if the delDeal is physical - it has to be valid for this movement
const validateMatchingShipToCode = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup?.movements.some(m => isPhysicalDeal(m.delDeal)) &&
    !!this.ticket.shipToCode?.id &&
    !this.shipToCodes?.map(s => s.id).includes(this.ticket.shipToCode?.id)
  ) {
    return {
      warning: true,
      message: "Ship To code does not match"
    };
  }
};

const isShipFromCodeRequired = function (
  userQueryCodes: TUserQueryCode[],
  movement: TMovement,
  qryCode: string
): boolean {
  if (skipValidationForSpecificProductGroups(movement, userQueryCodes, qryCode)) {
    return false;
  }

  if (skipValidationForSpecificLocations(movement)) {
    return false;
  }

  if (isMovementActualizedByGSAP(movement)) {
    return false;
  }

  if (skipValidationForMOTsOtherThanTruckAndRail(movement)) {
    return false;
  }

  if (skipValidationForInterBook(movement, "shipFrom")) {
    return false;
  }

  return (
    (isMovementHavingActivityTypeBackToBack(movement) &&
      isDestinationRule11Incoterm(movement)) ||
    isMovementPhysicalToStorage(movement)
  );
};

const isShipToCodeRequired = function (
  userQueryCodes: TUserQueryCode[],
  movement: TMovement,
  qryCode: string
): boolean {
  if (skipValidationForSpecificProductGroups(movement, userQueryCodes, qryCode)) {
    return false;
  }

  if (skipValidationForSpecificLocations(movement)) {
    return false;
  }

  if (isMovementActualizedByGSAP(movement)) {
    return false;
  }

  if (skipValidationForMOTsOtherThanTruckAndRail(movement)) {
    return false;
  }

  if (skipValidationForInterBook(movement, "shipTo")) {
    return false;
  }

  return (
    (isMovementHavingActivityTypeBackToBack(movement) &&
      isDestinationRule11Incoterm(movement)) ||
    isMovementStorageToPhysical(movement)
  );
};

const showErrorOnVolumeMeasureForPriceUnit = function (
  movement: TMovement,
  ticketUomName: string | undefined,
  ticketUomTypeName: string | undefined
): boolean {
  if (isInventoryGainLoss(movement)) {
    return false;
  }
  if (isMovementTransitOrStorage(movement)) {
    return (
      isTicketAndFinUomNotMatching(movement, ticketUomName, ticketUomTypeName) &&
      (isMassMeasureHavingZeroVolume(movement) || isVolumeMeasureHavingZeroVolume(movement))
    );
  }

  if (
    isMovementPhysToPhys(movement) &&
    isTicketAndFinUomNameNotMatching(movement, ticketUomName)
  ) {
    return (
      isTicketAndFinUomTypeNameNotMatching(movement, ticketUomTypeName) &&
      (isMassMeasureHavingZeroVolume(movement) || isVolumeMeasureHavingZeroVolume(movement))
    );
  }
  return false;
};

const showWarningForVolumeMeasure = function (
  movement: TMovement,
  ticketUomName: string | undefined,
  ticketUomTypeName: string | undefined
): boolean {
  if (
    isMovementTransitToPhysOrPhysToTransit(movement) &&
    isTicketAndFinUomNotMatching(movement, ticketUomName, ticketUomTypeName)
  ) {
    return true;
  }

  if (
    isMovementStorageToStorage(movement) &&
    equalsIgnoreCase(finUomTypeNameForStorage(movement), "Mass")
  ) {
    return true;
  }

  if (
    isMovementPhysToPhys(movement) &&
    isTicketAndFinUomNameNotMatching(movement, ticketUomName) &&
    isTicketAndFinUomTypeNameNotMatching(movement, ticketUomTypeName)
  ) {
    return true;
  }

  if (
    isMovementTansitToStorageOrStorageToTransit(movement) &&
    isMovementNotHavingMatchingUomOnBothDeals(movement)
  ) {
    return true;
  }
  return false;
};

const validateShipFromCode = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup?.movements.some(
      m =>
        isShipFromCodeRequired(this.userQueryCodes, m, "IgnoreShipFrom") &&
        !this.ticket.shipFromCode?.id
    )
  ) {
    return {
      error: true,
      message: "Ship From is a required field when the MOT is Truck or Rail"
    };
  }
};

const validateShipToCode = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup?.movements.some(
      m =>
        isShipToCodeRequired(this.userQueryCodes, m, "IgnoreShipTo") &&
        !this.ticket.shipToCode?.id
    )
  ) {
    return {
      error: true,
      message: "Ship To is a required field when the MOT is Truck or Rail"
    };
  }
};

const validateRequiresBothMassVolume = function (
  this: TTicketInputValidationData
): TTicketValidationResult | undefined {
  if (
    this.movementGroup?.movements.some(
      m => requireVolAndMassUnit(m) && (!hasVolumeRow(this.ticket) || !hasMassRow(this.ticket))
    )
  ) {
    return {
      error: true,
      message: "Movement requires both Volume and Mass quantity"
    };
  }
};

type TValidationConfigurer = {
  code: string;
  configurable: boolean;
  validator: (this: TTicketInputValidationData) => TTicketValidationResult | undefined;
  requiresMovement: boolean;
};
const ticketValidations: TValidationConfigurer[] = [
  {
    code: "ACT-0001",
    configurable: true,
    validator: validateTicketNumber,
    requiresMovement: false
  },

  {
    code: "ACT-0002",
    configurable: true,
    validator: validateRailcars,
    requiresMovement: false
  },

  {
    code: "ACT-0004",
    configurable: true,
    validator: validateGrossVolumesForGrossPricing,
    requiresMovement: false
  },

  {
    code: "ACT-0005",
    configurable: true,
    validator: validateCarrier,
    requiresMovement: true
  },

  {
    code: "ACT-0007",
    configurable: true,
    validator: validateGrossVolumesForMOT,
    requiresMovement: false
  },

  {
    code: "ACT-0016",
    configurable: true,
    validator: validateShipToCodeForReporting,
    requiresMovement: false
  },

  {
    code: "ACT-0017",
    configurable: true,
    validator: validateShipFromCodeForReporting,
    requiresMovement: false
  },

  {
    code: "ACT-0018",
    configurable: true,
    validator: validateShipToCodeForInternationalBorder,
    requiresMovement: false
  },

  {
    code: "ACT-0019",
    configurable: true,
    validator: validateShipFromCodeForInternationalBorder,
    requiresMovement: false
  },

  {
    code: "ACT-0022",
    configurable: true,
    validator: validateMassVolumeForSuperFundTax,
    requiresMovement: false
  },

  {
    code: "ACT-0023",
    configurable: true,
    validator: validateL15Volume,
    requiresMovement: false
  },

  {
    code: "ACT-0026",
    configurable: true,
    validator: validateVolumeMeasure,
    requiresMovement: false
  },

  {
    code: "ACT-0027",
    configurable: true,
    validator: validateShipToCodeForTax,
    requiresMovement: false
  },

  {
    code: "ACT-0028",
    configurable: true,
    validator: validateShipFromCodeForTax,
    requiresMovement: false
  },

  {
    code: "ACT-0006",
    configurable: true,
    validator: validateShipToCode,
    requiresMovement: false
  },

  {
    code: "ACT-0012",
    configurable: true,
    validator: validateShipFromCode,
    requiresMovement: false
  },

  {
    code: "ACT-0015",
    configurable: true,
    validator: validateDealPricingFormulaAdjustment,
    requiresMovement: false
  },

  {
    code: "ACT-0010",
    configurable: true,
    validator: validateVolumeMeasureIsGreaterThanZero,
    requiresMovement: false
  },

  {
    code: "ACT-0011",
    configurable: true,
    validator: validateVolumeMeasureUpdated,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateTicketDate,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateVolumes,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateDuplicateVolumes,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validatePageRange,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateStorageDates,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validatePlannedMovement,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateActualizedExternally,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateBatchTransitCompleted,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateCreditRedLine,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateFacility,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateProduct,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateLogisticsSystem,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateBatch,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateTemperature,
    requiresMovement: true
  },

  {
    code: "",
    configurable: false,
    validator: validateMatchingTicketDate,
    requiresMovement: true
  },

  {
    code: "",
    configurable: false,
    validator: validateMatchingShipFromCode,
    requiresMovement: true
  },

  {
    code: "",
    configurable: false,
    validator: validateMatchingShipToCode,
    requiresMovement: true
  },

  {
    code: "",
    configurable: false,
    validator: validateAutoActualized,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateActualizedBySet,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateCarrierScacCode,
    requiresMovement: false
  },

  {
    code: "",
    configurable: false,
    validator: validateRequiresBothMassVolume,
    requiresMovement: true
  }
];

export const validateTicketInput = (
  ticket: TTicketInput,
  movementGroup: TMovementGroup | null,
  numberOfPages: number | null,
  validationConfig: TValidationsConfig[],
  userQueryCodes: TUserQueryCode[],
  shipFromCodes?: TShipCode[],
  shipToCodes?: TShipCode[]
) => {
  return ticketValidations
    .filter(
      v =>
        !v.configurable ||
        movementGroup == null ||
        !skipValidation(validationConfig, movementGroup, v.code)
    )
    .filter(v => !v.requiresMovement || movementGroup != null)
    .map(f => {
      const result = f?.validator?.call({
        ticket,
        movementGroup,
        numberOfPages,
        validationConfig,
        userQueryCodes,
        shipFromCodes,
        shipToCodes
      });
      if (result) {
        return {
          ...result,
          error:
            getValidationType(
              validationConfig.find(c => c.code === f.code)?.mackValidationType
            ) ?? result?.error,
          warning:
            getValidationType(
              validationConfig.find(c => c.code === f.code)?.mackValidationType
            ) ?? result?.warning
        } as TTicketValidationResult;
      }
      return null;
    })
    .filter((n): n is TTicketValidationResult => Boolean(n));
};

export const validateTicket = (
  movementGroup: TMovementGroup,
  ticket: TTicket,
  temperatureUOMs: Picklists.TTemperatureUOM[],
  shipFromCodes: TShipCode[],
  shipToCodes: TShipCode[],
  validationConfigs: TValidationsConfig[],
  userQueryCodes: TUserQueryCode[]
) => {
  const ticketInput = {
    ...ticket,
    isNew: false,
    railcars: ticket.railcars?.join(","),
    collapsed: true,
    startDate: ticket.startDate as Date,
    borderCrossingDate: ticket.borderCrossingDate ? new Date(ticket.borderCrossingDate) : null,
    volumes: ticket.volumes.map(v => ({
      ...v,
      temperatureUnitOfMeasure: temperatureUOMs?.find(
        t => t.name === v.temperatureUnitOfMeasure
      ),
      isNew: false
    }))
  };
  return validateTicketInput(
    ticketInput,
    movementGroup,
    null,
    validationConfigs,
    userQueryCodes,
    shipFromCodes,
    shipToCodes
  );
};
