import { MutationFetchPolicy, useLazyQuery, useMutation } from "@apollo/client";
import { addMonths, firstDayOfMonth } from "@progress/kendo-date-math";
import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { MackUser } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import GlobalHeader from "global/sections/header";
import { loader } from "graphql.macro";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import {
  GqlResponse,
  TBatch,
  TBulkTicketUnlinkInput,
  TicketStatus,
  TLinkedTicket,
  TMovement,
  TMovementLinkInputEx,
  TTicket,
  TTicketSearchCriteria
} from "ticketing/ticketing.types";
import { toSearchLikedTicketFilterInput, toSearchTicketFilterInput } from "ticketing/utils";
import EnterpriseSystemSelect from "../enterpriseSystemSelect";
import SearchTicketsContainer from "./SearchTicketsContainer";
import TicketingGrid, { TGridTicket } from "./TicketingGrid";

const PADDING = 16;

const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};

type TMovementTickets = {
  id: string;
  version: number;
  enterpriseSystemCode: string;
  batch: TBatch;
  tickets: TLinkedTicket[];
  actualizationComplete: boolean;
};
type TicketsSearchResponse = GqlResponse<TTicket[], "ticketsFilterBy">;
type LinkedTicketsSearchResponse = GqlResponse<TMovementTickets[], "movements">;
type BulkUnlinkTicketResponse = GqlResponse<TMovement[], "movements">;
type AllocateVolumeResponse = GqlResponse<TMovement[], "bulkAllocateMovementVolume">;

const TICKETS_SEARCH = loader("../../ticketing-graphql/searchTickets.graphql");
const LINKED_TICKETS_SEARCH = loader(
  "../../ticketing-graphql/searchTicketsOfMovements.graphql"
);
const DELETE_TICKETS = loader("../../ticketing-graphql/deleteTicket.graphql");
const BULK_UNLINK_TICKETS = loader("../../ticketing-graphql/bulkUnlinkTickets.graphql");
const BULK_ALLOCATE_MOVEMENTS = loader(
  "../../ticketing-graphql/bulkAllocateMovementVolume.graphql"
);

const firstDayOfPrevMonth = () => firstDayOfMonth(addMonths(firstDayOfMonth(new Date()), -1));

const initSearchCriteria = (
  criteria?: TTicketSearchCriteria | null,
  user?: MackUser | null
): TTicketSearchCriteria => {
  return {
    startDate: criteria?.startDate ?? firstDayOfPrevMonth(),
    endDate: new Date(),
    batches: criteria?.batches ?? [],
    modeOfTransports: criteria?.modeOfTransports ?? [],
    ticketNumbers: criteria?.ticketNumbers,
    ticketStatus: TicketStatus.Unlinked,
    ticketSource: ["Manual"],
    lastModifiedBy: criteria?.lastModifiedBy ?? user?.mackUser?.id
  };
};

const transformTickets = (tickets: TTicket[] | TLinkedTicket[]) => {
  return tickets
    ?.map(t => ({
      ...t,
      startDate: toKString(new Date(t.startDate), "yyyy-MM-dd"),
      borderCrossingDate: t.borderCrossingDate
        ? toKString(new Date(t.borderCrossingDate), "yyyy-MM-dd")
        : "",
      lastModifiedDate: toKString(new Date(t.lastModifiedDate + "Z"), "yyyy-MM-dd hh:mm:ss"),
      grossVolume: t.volumes[0]?.grossVolume ?? 0,
      netVolume: t.volumes[0]?.netVolume ?? 0,
      railcars: t.railcars?.map(r => r.railcarNumber).join(","),
      volumeUnit: t.volumes[0]?.unitOfMeasure?.name ?? "",
      deliveryIds:
        (t as TLinkedTicket).movements?.map(m => m.enterpriseSystemCode).join(", ") ?? "",
      transitComplete: (t as TLinkedTicket).movements?.some(m => m.batch?.transitComplete)
    }))
    .sort((t1, t2) => +t2.id - +t1.id);
};

const transformMovementTickets = (movements: TMovementTickets[], ticketNumbers?: string) => {
  const allTickets = movements
    .filter(m => m.tickets?.length)
    .flatMap(m =>
      m.tickets
        .filter(t => !ticketNumbers || ticketNumbers?.includes(t.ticketNumber))
        .map(t => ({
          ...t,
          movements: [
            {
              id: m.id,
              version: m.version,
              enterpriseSystemCode: m.enterpriseSystemCode,
              batch: m.batch,
              actualizationComplete: m.actualizationComplete
            }
          ]
        }))
    );

  return allTickets.reduce<TLinkedTicket[]>((a, i) => {
    const existing = a.find(t => t.id === i.id);
    if (existing) {
      existing.movements?.push(...i.movements);
    } else {
      a.push(i);
    }
    return a;
  }, []);
};

type TDeleteTicketState = {
  showConfirmation: boolean;
  ticketIds?: string[];
};

type TUnlinkTicketState = {
  showConfirmation: boolean;
  input?: TBulkTicketUnlinkInput[];
};
/**
 *
 * @returns
 */
const ManageTickets = () => {
  const location = useLocation();

  const pathName = location.pathname;

  const { mackUser } = useMackAuth();
  const { picklists, isLoading: picklistsLoading, isReady: picklistsReady } = usePicklists();
  const [tickets, setTickets] = useState<TGridTicket[]>();
  const [ticketSearchCriteria, setTicketSearchCriteria] = useState<TTicketSearchCriteria>();
  const searchFormRef = useRef<HTMLDivElement>(null);
  const [searchFormOpen, setSearchFormOpen] = useState(true);
  const [searchCalled, setSearchCalled] = useState(false);
  const [deleteTicketState, setDeleteTicketState] = useState<TDeleteTicketState>({
    showConfirmation: false
  });
  const [unlinkTicketState, setUnlinkTicketState] = useState<TUnlinkTicketState>({
    showConfirmation: false
  });
  const [top, setTop] = useState<number>(0);

  const [searchTickets, { loading: searchTicketsLoading, error: searchTicketsError }] =
    useLazyQuery<TicketsSearchResponse>(TICKETS_SEARCH, {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => setTickets(transformTickets(data.ticketsFilterBy))
    });

  const [
    searchLinkedTickets,
    { loading: searchLinkedTicketsLoading, error: searchLinkedTicketsError }
  ] = useLazyQuery<LinkedTicketsSearchResponse>(LINKED_TICKETS_SEARCH, {
    ...FETCH_POLICY_NO_CACHE,
    onCompleted: data =>
      setTickets(
        transformTickets(
          transformMovementTickets(data.movements, ticketSearchCriteria?.ticketNumbers)
        )
      )
  });

  const [deleteTickets, { loading: deleteTicketsLoading, error: deleteTicketsError }] =
    useMutation(DELETE_TICKETS);

  const [
    bulkUnlinkTickets,
    { loading: loadingBulkUnlinkTickets, error: errorBulkUnlinkTickets }
  ] = useMutation<BulkUnlinkTicketResponse>(BULK_UNLINK_TICKETS);

  const [bulkAllocateMovementVolume, { loading: allocateLoading, error: allocateError }] =
    useMutation<AllocateVolumeResponse>(BULK_ALLOCATE_MOVEMENTS, {
      fetchPolicy: "no-cache"
    });

  useEffect(() => {
    setTop(
      (searchFormRef?.current?.getBoundingClientRect().top ?? 0) +
        (searchFormRef?.current?.getBoundingClientRect().height ?? 0) +
        PADDING
    );
  }, [searchFormRef, searchFormOpen, searchCalled]);

  const getSearchCriteria = () => {
    return ticketSearchCriteria ?? initSearchCriteria(null, mackUser);
  };

  const getTicketsForSearch = useCallback(
    (criteria: TTicketSearchCriteria) => {
      if (criteria.deliveryIds?.trim()) {
        searchLinkedTickets({
          variables: {
            filter: toSearchLikedTicketFilterInput(criteria, picklists?.enterpriseSystemId!)
          }
        });
      } else {
        searchTickets({
          variables: {
            filter: toSearchTicketFilterInput(criteria)
          }
        });
      }
    },
    [searchTickets, searchLinkedTickets, picklists?.enterpriseSystemId]
  );

  const handleSearch = useCallback(
    (criteria: TTicketSearchCriteria) => {
      setSearchCalled(true);
      setTicketSearchCriteria(criteria);
      setTickets([]);
      getTicketsForSearch(criteria);
    },
    [getTicketsForSearch]
  );

  const onRefresh = useCallback(() => {
    if (ticketSearchCriteria) {
      getTicketsForSearch(ticketSearchCriteria);
    }
  }, [getTicketsForSearch, ticketSearchCriteria]);

  const onReset = useCallback(() => {
    setTicketSearchCriteria(undefined);
  }, []);

  const onDeleteTickets = useCallback((ticketIds: string[]) => {
    setDeleteTicketState({ showConfirmation: true, ticketIds });
  }, []);

  const onCancelDeleteTicket = useCallback(() => {
    setDeleteTicketState({ showConfirmation: false });
  }, []);

  const onDeleteTicketConfirmed = useCallback(() => {
    setDeleteTicketState({ showConfirmation: false });
    if (deleteTicketState.ticketIds) {
      deleteTickets({
        variables: { ids: deleteTicketState.ticketIds },
        onCompleted: () => {
          onRefresh();
        }
      });
    }
  }, [deleteTickets, onRefresh, deleteTicketState.ticketIds]);

  const onUnlinkTickets = useCallback((input: TBulkTicketUnlinkInput[]) => {
    setUnlinkTicketState({ showConfirmation: true, input });
  }, []);

  const onCancelUnlinkTicket = useCallback(() => {
    setUnlinkTicketState({ showConfirmation: false });
  }, []);

  const saveAllocations = useCallback(
    (input: TBulkTicketUnlinkInput[], onDone: () => void) => {
      const allocateVolumeInput = input
        .flatMap(t => t.movements)
        .filter(m => (m as TMovementLinkInputEx).actualizationComplete)
        .map(srcMovement => ({
          srcId: srcMovement.movementId,
          version: srcMovement.version,
          volume: 0,
          reason: "Ticketing Grid: Bulk unlink tickets",
          targets: [
            { movementId: srcMovement.movementId, version: srcMovement.version, volume: 0 }
          ]
        }));
      if (allocateVolumeInput?.length) {
        bulkAllocateMovementVolume({
          variables: { input: allocateVolumeInput },
          onCompleted: () => onDone()
        });
      } else {
        onDone();
      }
    },
    [bulkAllocateMovementVolume]
  );

  const onUnlinkTicketsConfirmed = useCallback(() => {
    setUnlinkTicketState({ showConfirmation: false });
    if (unlinkTicketState.input) {
      saveAllocations(unlinkTicketState.input, () => {
        const input = unlinkTicketState.input?.map(t => ({
          ticketIds: t.ticketIds,
          movements: t.movements.map(m => ({ movementId: m.movementId, version: m.version }))
        }));
        bulkUnlinkTickets({
          variables: { input },
          onCompleted: onRefresh
        });
      });
    }
  }, [bulkUnlinkTickets, unlinkTicketState.input, saveAllocations, onRefresh]);

  if (picklistsLoading || !picklistsReady) {
    return <InlineLoadingPanel />;
  }

  const loading = [
    searchTicketsLoading,
    searchLinkedTicketsLoading,
    deleteTicketsLoading,
    loadingBulkUnlinkTickets,
    allocateLoading
  ].some(l => l);

  return (
    <>
      {pathName === "/ticketing/manageTickets" && (
        <GlobalHeader
          pageName="Manage Tickets"
          buttonContent={[<EnterpriseSystemSelect key={1} />]}
        />
      )}

      <div id="Ticketing" className="tickets-page">
        <div ref={searchFormRef}>
          <SearchTicketsContainer
            searchCriteria={getSearchCriteria()}
            onSearch={handleSearch}
            open={searchFormOpen}
            onCollapsed={collapsed => setSearchFormOpen(collapsed)}
            onReset={onReset}
            loading={loading}
          />
        </div>
        {loading && <InlineLoadingPanel />}
        {searchTicketsError && <ApolloErrorViewer error={searchTicketsError} />}
        {searchLinkedTicketsError && <ApolloErrorViewer error={searchLinkedTicketsError} />}
        {allocateError && <ApolloErrorViewer error={allocateError} />}
        {deleteTicketState.showConfirmation && (
          <Dialog title={"Delete Ticket"} onClose={onCancelDeleteTicket}>
            <div className="component-title" style={{ padding: "16px" }}>
              Are you sure you want to delete selected tickets?
            </div>
            <DialogActionsBar>
              <Button icon="cancel" onClick={onCancelDeleteTicket}>
                Cancel
              </Button>
              <Button themeColor={"primary"} icon="check" onClick={onDeleteTicketConfirmed}>
                Delete
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
        {unlinkTicketState.showConfirmation && (
          <Dialog title={"Unlink Tickets"} onClose={onCancelDeleteTicket}>
            <div className="component-title" style={{ padding: "16px" }}>
              Are you sure you want to unlink selected tickets?
            </div>
            <DialogActionsBar>
              <Button icon="cancel" onClick={onCancelUnlinkTicket}>
                Cancel
              </Button>
              <Button themeColor={"primary"} icon="check" onClick={onUnlinkTicketsConfirmed}>
                Unlink
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
        {deleteTicketsError && <ApolloErrorViewer error={deleteTicketsError} />}
        {errorBulkUnlinkTickets && <ApolloErrorViewer error={errorBulkUnlinkTickets} />}
        {searchCalled && (
          <div
            style={{
              height: `calc(100vh - ${top}px)`,
              boxSizing: "border-box"
            }}>
            <TicketingGrid
              tickets={tickets ?? []}
              onRefresh={onRefresh}
              loading={loading}
              onDelete={onDeleteTickets}
              onUnlink={onUnlinkTickets}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ManageTickets;
