import { ApolloError } from "@apollo/client";
import { TEnterpriseSystem } from "types";

export type { GqlResponse } from "types";

export namespace Picklists {
  export type Picklist<T> = {
    id: T;
    name: string;
  };

  export type TMovementStatus = Picklist<number>;
  export type TModeOfTransport = Picklist<number>;
  export type PDeliveryEventType = Picklist<number>;
  export type PMeasurementType = Picklist<number>;
  export type TInternalLegalEntity = Picklist<string>;
  export type PLogisticsSystem = Picklist<number>;

  export type TUomClass = {
    name: string;
  };

  export type TUnitOfMeasure = Picklist<number> & {
    unitOfMeasureClass: TUomClass;
  };

  export type TUomConversion = {
    id: number;
    srcUnitOfMeasure: TUnitOfMeasure;
    destUnitOfMeasure: TUnitOfMeasure;
    factor: number;
  };

  export type TTemperatureUOM = Picklist<string> & {
    shortName: string;
  };
}

export type TPicklists = {
  enterpriseSystemId: number;
  movementStatuses?: Picklists.TMovementStatus[];
  modeOfTransports?: Picklists.TModeOfTransport[];
  deliveryEventTypes?: Picklists.PDeliveryEventType[];
  measurementTypes?: Picklists.PMeasurementType[];
  unitOfMeasures?: Picklists.TUnitOfMeasure[];
  legalEntities?: Picklists.TInternalLegalEntity[];
  uomConversions?: Picklists.TUomConversion[];
  temperatureUOMs?: Picklists.TTemperatureUOM[];
  shipFrom?: TShipCode[];
  shipTo?: TShipCode[];
  logisticsSystems?: Picklists.PLogisticsSystem[];
};

export type TPicklistContext = {
  picklists?: TPicklists;
  isLoading?: boolean;
  isReady: boolean;
  errors?: (ApolloError | Error)[];
};

export type TBatch = Picklists.Picklist<string> & {
  version?: number;
  logisticsSystem?: TLogisticsSystem;
  transitDealNum?: number;
  transitComplete?: boolean;
  modeOfTransport: Picklists.TModeOfTransport;
  movements?: Partial<TMovement>[];
  transport?: TBatchMarine[];
};
type TBatchMarine = {
  carriages: TMarineCarriage[];
};
export type TProduct = Picklists.Picklist<number> & {
  superFundTax?: string;
  userProductGroup?: TUserProductGroup;
};
export type TCounterParty = Picklists.Picklist<string> & {
  requiresShipCodeFor?: string[] | null;
  parentLegalEntity: Picklists.TInternalLegalEntity;
};
export type TLogisticsSystem = Picklists.Picklist<number>;
export type TCarrier = Picklists.Picklist<string> & {
  scac: string;
  displayName: string;
};
export type TFacility = Picklists.Picklist<string> & {
  isInternationalBorder?: boolean;
};

export type TInstrument = {
  insTypeId: number;
  name: string;
  baseInstrument: TInstrument;
};

export type TUserProductGroup = {
  productGroupId: number;
  productGroupName: string;
};

export type TBatchSearchCriteria = {
  startDate: Date;
  endDate: Date;
  products?: TProduct[];
  deliveryIds?: string;
  counterParties?: TCounterParty[];
  facilities?: TFacility[];
  batches?: TBatch[];
  legalEntities?: Picklists.TInternalLegalEntity[];
  carriers?: TCarrier[];
  modeOfTransports?: Picklists.TModeOfTransport[];
  logisticsSystems?: TLogisticsSystem[];
  railcarNumbers?: string;
  ticketNumbers?: string;
  ticketSource?: string[];
  ticketStatus?: TicketStatus;
  shipFromCodes?: TShipCode[];
  shipToCodes?: TShipCode[];
  statusId?: number;
  includeNulls?: boolean;
};

export type TTicketSearchCriteria = {
  startDate: Date;
  endDate: Date;
  batches?: TBatch[];
  modeOfTransports?: Picklists.TModeOfTransport[];
  ticketNumbers?: string;
  deliveryIds?: string;
  ticketSource?: string[];
  ticketStatus?: string;
  lastModifiedBy?: string;
};

export type TBatchFilterInput = {
  enterpriseSystemId: number;
  startDate: DateQueryOperatorInput;
  endDate: DateQueryOperatorInput;
  active?: BooleanQueryOperatorInput;
  batchId?: LongQueryOperatorInput;
  name?: StringQueryOperatorInput;
  carrierId?: LongQueryOperatorInput;
  counterPartyId?: IntegerQueryOperatorInput;
  facilityId?: LongQueryOperatorInput;
  internalLegalEntityId?: IntegerQueryOperatorInput;
  logisticsSystemId?: IntegerQueryOperatorInput;
  modeOfTransportId?: IntegerQueryOperatorInput;
  productId?: IntegerQueryOperatorInput;
  movementEnterpriseSystemCode?: StringQueryOperatorInput;
  movementType?: string; //MovementType;
  railcarNumber?: StringQueryOperatorInput;
  statusId?: IntegerQueryOperatorInput;
};

export type TMovementFilterInput = Omit<TBatchFilterInput, "movementEnterpriseSystemCode"> & {
  activityTypeId?: IntegerQueryOperatorInput;
  aggregationType?: StringQueryOperatorInput;
  customsNumber?: StringQueryOperatorInput;
  delDealId?: LongQueryOperatorInput;
  recDealId?: LongQueryOperatorInput;
  delStrategy?: StringQueryOperatorInput;
  recStrategy?: StringQueryOperatorInput;
  unitOfMeasureId?: IntegerQueryOperatorInput;
  enterpriseSystemCode?: StringQueryOperatorInput;
};

export type TLinkedTicketFilterInput = {
  enterpriseSystemId: number;
  ticketNumber?: StringQueryOperatorInput;
  enterpriseSystemCode?: StringQueryOperatorInput;
};

export type TTicketFilterInput = {
  startDate: DateQueryOperatorInput;
  endDate: DateQueryOperatorInput;
  batchId?: LongQueryOperatorInput;
  ticketNumber?: StringQueryOperatorInput;
  carrierId?: LongQueryOperatorInput;
  facilityId?: LongQueryOperatorInput;
  logisticsSystemId?: IntegerQueryOperatorInput;
  modeOfTransportId?: IntegerQueryOperatorInput;
  productId?: IntegerQueryOperatorInput;
  railcarNumber?: StringQueryOperatorInput;
  shipFromCodeId?: LongQueryOperatorInput;
  shipToCodeId?: LongQueryOperatorInput;
  source?: TicketSourceFilterInput;
  status?: string;
  lastModifiedBy?: StringQueryOperatorInput;
};

type TMovementLinkInput = {
  movementId: string;
  version: number;
};

export type TMovementLinkInputEx = TMovementLinkInput & {
  actualizationComplete?: boolean;
};

export type TBulkTicketUnlinkInput = {
  movements: (TMovementLinkInput | TMovementLinkInputEx)[];
  ticketIds: string[];
};

export type QueryOperatorInput<T> = {
  eq?: T;
  in?: T[];
  inOrNull?: T[];
  ne?: T;
  nin?: T[];
  regex?: T;
};

export enum TicketStatus {
  Cancelled = "CANCELLED",
  Linked = "LINKED",
  Unlinked = "UNLINKED"
}

export enum TicketSource {
  Gsap = "GSAP",
  Import = "IMPORT",
  Manual = "MANUAL"
}

export type TicketSourceFilterInput = Omit<
  QueryOperatorInput<TicketSource>,
  "inOrNull" | "regex"
>;

export type IntegerQueryOperatorInput = QueryOperatorInput<number> & {
  loe?: number;
  lt?: number;
  ne?: number;
  nin?: number[];
};

export type StringQueryOperatorInput = QueryOperatorInput<string> & {
  regexIgnoreCase?: string;
};

export type LongQueryOperatorInput = QueryOperatorInput<string>;

export type BooleanQueryOperatorInput = {
  eq: boolean;
  ne: boolean;
};

export type DateBetweenQueryOperatorInput = {
  start: string;
  end: string;
};

export type DateQueryOperatorInput = {
  bw?: DateBetweenQueryOperatorInput;
  eq?: string;
  goe?: string;
  gt?: string;
  loe?: string;
  lt?: string;
};

export type TUser = {
  id: string;
  firstName?: string;
  lastName: string;
  middleName?: string;
};

export type TBatchRevision = {
  name: string;
  lastModifiedBy: TUser;
  lastModifiedDate: string | Date;
  revisionDateTime: string;
  revisionNumber: string;
  transitComplete: boolean;
  transitDealNum: number;
};

export enum MovementDeliveryEventStatus {
  ESTIMATED,
  FINAL
}

export type TDeliveryEventType = Picklists.PDeliveryEventType & {
  version: number;
  fullName: string;
  active: boolean;
  description: string;
  enterpriseSystem: TEnterpriseSystem;
  enterpriseSystemCode: string;
};

export type TMovementDeliveryEvent = {
  id: string;
  version: number;
  // deliveryEventType?: Partial<TDeliveryEventType>;
  deliveryEventType?: Picklists.PDeliveryEventType;
  deliveryEventDate: Date;
  deliveryEventStatus: MovementDeliveryEventStatus;
};

export type TDealDeliveryEvent = Omit<TMovementDeliveryEvent, "deliveryEventStatus"> & {
  requiredForPayment: boolean;
  requiredForPricing: boolean;
};

export type TVolumeAllocationsOfMovementAtRevision = {
  groupId: string;
  id: string;
  lastModifiedBy: TUser;
  lastModifiedDate: Date | string;
  srcEnterpriseSystemCode: string;
  targetEnterpriseSystemCode: string;
  reason?: string | null;
  volume: string | number;
};

export type TAlertsOfMovementAtRevision = {
  category: string;
  code: string;
  desc: string;
};

export type TMeasurementType = {
  id: number;
  version: number;
  name: string;
  fullName: string;
  active: boolean;
  description: string;
  enterpriseSystem: TEnterpriseSystem;
  enterpriseSystemCode: string;
};

export type TMeasuresOfMovementAtRevision = {
  id: number;
  measurementType: TMeasurementType;
  unitOfMeasure: Partial<Picklists.TUnitOfMeasure>;
  value: number;
};

export type TMovementTicketRevision = {
  id: string;
  ticketNumber: string;
  linkStatus: TicketStatus;
  lastLinkModifiedBy: string;
  lastLinkModifiedDate: string;
};

export type TVolumeInput = Partial<Omit<TVolume, "temperatureUnitOfMeasure">> & {
  id: string;
  version?: number;
  isNew: boolean;
  isDefault?: boolean;
  volumeType?: string;
  temperatureUnitOfMeasure?: Picklists.TTemperatureUOM;
};

export type TVolume = {
  id: string;
  sequence: number;
  netVolume: number;
  grossVolume?: number;
  unitOfMeasure: Picklists.TUnitOfMeasure;
  temperature: number;
  temperatureUnitOfMeasure: string;
};

export type TTicketValidationResult = {
  error?: boolean;
  warning?: boolean;
  message?: string;
};

export type TValidatedTicket = TTicket & {
  validationResults?: TTicketValidationResult[];
  matchingVolume?: TVolume;
  converted?: boolean;
};

export type TTicketMeasure = {
  measurementType: TMeasurementType;
  unitOfMeasure: Picklists.TUnitOfMeasure;
  value: number;
};

type TShipCodeDisplay = TShipCode & {
  display?: string;
};

export type TTicketInput = Partial<
  Omit<
    TTicket,
    "railcars" | "volumes" | "startDate" | "borderCrossingDate" | "shipFromCode" | "shipToCode"
  >
> & {
  isNew: boolean;
  startDate?: Date;
  volumes: TVolumeInput[];
  shipFromCode?: TShipCodeDisplay | null;
  shipToCode?: TShipCodeDisplay | null;
  borderCrossingDate?: Date | null;
  railcars?: string | null;
  collapsed: boolean;
  errors?: (TTicketValidationResult | undefined)[];
};

export type TRailcar = {
  railcarNumber: string;
};

export type TTicket = {
  id: string;
  version: number;
  batch?: TBatch;
  carrier?: TCarrier;
  carrierScacCode?: string;
  documentId?: string;
  pageNumbers?: string | null;
  facility?: TFacility;
  logisticsSystem?: TLogisticsSystem;
  measures?: TTicketMeasure[];
  modeOfTransport?: Picklists.TModeOfTransport;
  product?: TProduct;
  railcars?: TRailcar[];
  shipFromCode?: TShipCode;
  shipToCode?: TShipCode;
  source: string;
  startDate: string | Date;
  status: string;
  ticketNumber: string;
  volumes: TVolume[];
  poNumber?: string;
  invoiceComment?: string;
  borderCrossingDate?: string | Date | null;
  actualizedVolume?: number;
  lastModifiedBy?: TUser;
  lastModifiedDate?: string | Date | null;
};

export type TLinkedTicket = TTicket & {
  actualizedVolume: number;
  movements?: Partial<TMovement>[];
};

enum TicketAggregationMode {
  MOVEMENT,
  SHIP_CODE,
  SETTLEMENT_SPLIT,
  TICKET
}

export type TActivityType = {
  id: number;
  name: string;
  serviceProviderId?: number;
  serviceProvider: string;
};
export type TIncoterm = {
  id: number;
  name: string;
};

export type TMovementRevision = {
  activityType: TActivityType;
  actualizationComplete: boolean;
  actualizedBy: string;
  actualizedQuantity: number;
  aggregationType: string;
  batchId: string;
  customsNumber: string;
  delDealId: string;
  delStrategy: string;
  endDate: Date;
  enterpriseSystem: TEnterpriseSystem;
  enterpriseSystemCode: string;
  grossQuantity: number;
  groupId: string;
  id: string;
  incoterm: TIncoterm;
  internalLegalEntity: Picklists.TInternalLegalEntity;
  isActualizedExternally: boolean;
  lastModifiedBy: TUser;
  lastModifiedDate: string | Date;
  logisticsSystem: TLogisticsSystem;
  movementType: "MOVEMENT" | "TICKET";
  netQuantity: number;
  parentId: string;
  product: TProduct;
  readyToInvoice: boolean;
  recDealId: string;
  recStrategy: string;
  revisionDateTime: Date | string;
  revisionNumber: string;
  revisionType: "ADD" | "MOD" | "DEL";
  scheduledQuantity: number;
  startDate: Date;
  status: Partial<Picklists.TMovementStatus>;
  ticketAggregationMode: TicketAggregationMode;
  titleTransferFacility: TFacility;
  unitOfMeasure: Picklists.TUnitOfMeasure;
};

export type TTicketInputValidationData = {
  ticket: TTicketInput;
  movementGroup: TMovementGroup | null;
  numberOfPages: number | null;
  validationConfig: TValidationsConfig[];
  userQueryCodes: TUserQueryCode[];
  shipFromCodes: TShipCode[] | undefined;
  shipToCodes: TShipCode[] | undefined;
};

export type TValidationsConfig = {
  id: number;
  mackActive: number;
  active: number;
  mackValidationType: string;
  code: string;
  legalEntityId: string;
  legalEntity: Picklists.TInternalLegalEntity;
  messageText: string;
  objectTypeName?: string;
  validationTypeName?: string;
  redlineCategory?: string;
  businessUnitDefined?: string;
  enforeOutsideCmotionIndicator?: number;
  enterpriseSystemCode?: string;
  runForAggregated?: number;
  runForCancelledNomIndicator?: number;
  runForConvertedDataIndicator?: number;
  runForGainLossNomIndicator?: number;
  runForNonTransitIndicator?: number;
  runForPaperRinsNomIndicator?: number;
  runForPlannedNomIndicator?: number;
  runForRinsNomIndicator?: number;
  runForSoftDeleteIndicator?: number;
  runForTransitIndicator?: number;
  runForZeroVolumeIndicator?: number;
  validatorScript?: string;
};

export type TUserQueryCode = {
  qry: string;
  code: string;
  descr: string;
  valueInt1: number;
  valueInt2: number;
  valueAmt1: number;
  valueAmt2: number;
  valueString1: string;
  valueString2: string;
  valueDate1: Date;
  valueDate2: Date;
};

export type TShipCode = {
  id: string;
  version: number;
  code: string;
  description: string;
  type: "SHIP_FROM" | "SHIP_TO";
  maps?: TShipCodeMap[];
};

export type TTicketRevision = {
  ticketNumber: string;
  revisionType: "ADD" | "MOD" | "DEL";
  id: string;
  lastModifiedBy: TUser;
  lastModifiedDate: string | Date;
  startDate: string | Date;
  batch: Partial<TBatch>;
  shipToCode: Partial<TShipCode>;
  shipFromCode: Partial<TShipCode>;
  product: TProduct;
  carrier: TCarrier;
  carrierScacCode: string;
  invoiceComment: string;
  borderCrossingDate: string | Date;
  poNumber: string;
  revisionNumber: string;
};

export type TTicketMovementRevision = {
  id: string;
  enterpriseSystemCode: string;
  lastModifiedBy: TUser;
  lastModifiedDate: string | Date;
};

export type TTicketVolumeRevision = {
  id: string;
  netVolume: number;
  grossVolume: number;
  unitOfMeasure: Picklists.TUnitOfMeasure;
  temperature: number;
  temperatureUnitOfMeasure: string;
  lastModifiedDate: string | Date;
  lastModifiedBy: TUser;
};

export type TTicketRailcarRevision = {
  lastModifiedBy: TUser;
  lastModifiedDate: string | Date;
  railcarNumber: string;
};

export type TShipCodeMap = {
  counterParty: Picklists.Picklist<string> | null;
  internalParty?: Picklists.TInternalLegalEntity | null;
  facility?: TFacility | null;
};

export type TContractType = {
  active: boolean;
  category: string;
  description: string;
  enterpriseSystem: TEnterpriseSystem;
  enterpriseSystemCode: string;
  fullName: string;
  id: number;
  name: string;
  version: number;
};

export type TCommitment = {
  rule11?: string;
  endDate?: string | Date;
  startDate?: string | Date;
  requiredGrossVolume?: boolean;
  incoterm?: TIncoterm;
};

export type TDeal = {
  id: string;
  scheduleEndDate?: string | Date;
  scheduleStartDate?: string | Date;
  requireTicketVolAndMassUnit: boolean;
  qtyUnitOfMeasure: Picklists.TUnitOfMeasure;
  contractType?: Partial<TContractType>;
  deliveryEvents: TDealDeliveryEvent[];
  contractNumber: string;
  parcelMaxQuantity?: number;
  commitment?: TCommitment;
  counterParty: TCounterParty;
  pricingUnitOfMeasure: Picklists.TUnitOfMeasure;
  hasFormulaPricing: boolean;
  insType: TInstrument;
  __typename?: string;
};

export type TCarriageType = {
  id: number;
  name: string;
  version: number;
  active: boolean;
  description: string;
  enterpriseSystem: TEnterpriseSystem;
  enterpriseSystemCode: string;
  fullName: string;
};

export type TMarineCarriage = {
  IMONumber: string;
  carriageType: TCarriageType;
  description: string;
  enterpriseSystem: TEnterpriseSystem;
  enterpriseSystemCode: string;
  fullName: string;
  id: number;
  managementCode: string;
  name: string;
  version: number;
};

export type TMovementTruck = {
  carrier: TCarrier;
};
export type TMovementRail = {
  id: string;
  railcarNumber: string;
  carrier: TCarrier;
};
export type TMovementMarine = {
  carriages: TMarineCarriage;
};

export type TMovementAlert = {
  category: string;
  code: string;
  desc: string;
};

export type TMovementComment = {
  comment: string;
  seqNum: number;
};

export type TMovementMeasure = {
  id: string;
  measurementType: Picklists.PMeasurementType;
  unitOfMeasure: Picklists.TUnitOfMeasure;
  value: number;
  version: number;
};

export type TMovement = {
  activityType?: TActivityType;
  actualizationComplete: boolean;
  actualizedQuantity: number;
  aggregationType: string;
  alerts: TMovementAlert[];
  batch: TBatch;
  customsNumber: string;
  delDeal: TDeal;
  deliveryEvents: TMovementDeliveryEvent[];
  delStrategy?: string;
  endDate: string | Date;
  enterpriseSystem: TEnterpriseSystem;
  enterpriseSystemCode: string;
  grossQuantity: number;
  groupId: string;
  id: string;
  incoterm?: TIncoterm;
  internalLegalEntity: Picklists.TInternalLegalEntity;
  isActualizedExternally: boolean;
  logisticsSystem: TLogisticsSystem;
  measures: TMovementMeasure[];
  movementType: string;
  netQuantity: number;
  product: TProduct;
  readyToInvoice: boolean;
  recDeal: TDeal;
  recStrategy?: string;
  scheduledQuantity: number;
  startDate: string | Date;
  status: Picklists.TMovementStatus;
  ticketAggregationMode: string;
  tickets: Partial<TTicket>[];
  titleTransferFacility: TFacility;
  transport?: TMovementTruck | TMovementRail | TMovementMarine;
  unitOfMeasure: Picklists.TUnitOfMeasure;
  version: number;
  infoFields?: [TInfoField];
  comments?: [TMovementComment];
  children?: Partial<TMovement>[];
};

export type TInfoField = {
  name: string;
  code: string;
  value?: string | null;
};
export type TMovementGroup = {
  groupId: string;
  movements: TMovement[];
  activeMovement: TMovement;
  activeChild?: Partial<TMovement> | null;
};

export type TNullableMovementGroup = TMovementGroup | undefined | null;

export type TAllocationMovement = {
  id: string;
  version: number;
  startDate: Date;
  endDate: Date;
  deliveryId: string;
  scheduledQuantity: number;
  actualizedQuantity: number;
  quantityAllocated: number;
  finalScheduledQuantity: number;
  inEdit: boolean;
};

export type TTicketInputAdd = {
  ticketNumber: string;
  startDate: string;
  endDate: string;
  batchId?: string;
  productId?: number;
  facilityId?: string;
  carrierId?: string;
  carrierScacCode?: string;
  logisticsSystemId?: number;
  modeOfTransportId?: number;
  shipFromCodeId?: string;
  shipToCodeId?: string;
  source: TicketSource;
  extSourceName?: string;
  extSourceTicketRef?: string;
  pageNumbers?: string;
  invoiceComment?: string;
  borderCrossingDate?: string;
  poNumber?: string;
  documentId?: string;
  volumes: TVolumeInputAdd[];
  railcars?: TRailcarInput[];
};

export type TTicketInputUpdate = {
  id: string;
  version: number;
  ticketNumber: string;
  startDate: string;
  endDate: string;
  batchId: string | null;
  productId: number | null;
  facilityId: string | null;
  carrierId?: string | null;
  carrierScacCode?: string | null;
  logisticsSystemId: number | null;
  modeOfTransportId: number | null;
  shipFromCodeId: string | null;
  shipToCodeId: string | null;
  pageNumbers: string | null;
  invoiceComment: string | null;
  borderCrossingDate: string | null;
  poNumber: string | null;
  documentId: string | null;
  volumes: TVolumeInputUpdate[];
  railcars: TRailcarInput[] | null;
};

export type TVolumeInputAdd = {
  sequence: number;
  netVolume: number;
  grossVolume?: number;
  unitOfMeasureId: number;
  temperature: number;
  temperatureUnitOfMeasure: string;
};

export type TVolumeInputUpdate = Omit<TVolumeInputAdd, "grossVolume"> & {
  id: string | null;
  grossVolume: number | null;
};

export type TRailcarInput = {
  railcarNumber: string;
};

export type TDocumentUploadResult = {
  id: string;
  fileName: string;
};

export type TNullableString = string | null | undefined;

export type TPdfDocument = {
  documentId: string | null;
  docFile: File | null;
  numberOfPages: number | null;
  loading?: boolean;
  showViewer: boolean;
};
