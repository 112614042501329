import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse, TMeasuresOfMovementAtRevision } from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

type Response = GqlResponse<TMeasuresOfMovementAtRevision[], "measuresOfMovementAtRevision">;

const GET_MEASURES_REVISIONS_OF_MOVEMENT = loader(
  "../../ticketing-graphql/measuresOfMovementAtRevision.graphql"
);

type RevisionsMovementMeasuresProps = {
  movementId: string;
  revisionNumber: string;
};
const RevisionsMovementMeasures = ({
  movementId,
  revisionNumber
}: RevisionsMovementMeasuresProps) => {
  const { loading, error } = useQuery<Response>(GET_MEASURES_REVISIONS_OF_MOVEMENT, {
    fetchPolicy: "no-cache",
    variables: { movementId, revisionNumber },
    onCompleted: data => setMeasures(data.measuresOfMovementAtRevision)
  });

  const [measures, setMeasures] = useState<TMeasuresOfMovementAtRevision[]>();

  const childData = [
    {
      title: "Measure Type",
      field: "measurementType.name",
      key: 1
    },
    { title: "Measure Unit", field: "unitOfMeasure.name", key: 2 },
    { title: "Measure", field: "value", key: 3 }
  ];
  return (
    <RevisionsGridWrapper
      loading={loading}
      error={error}
      dataContent={measures}
      columns={childData}
    />
  );
};

export default RevisionsMovementMeasures;
