import { useQuery } from "@apollo/client";
import { GridCellProps } from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse, TTicketMovementRevision } from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

type Response = GqlResponse<TTicketMovementRevision[], "movementsOfTicketAtRevision">;

const query = loader("../../ticketing-graphql/movementsOfTicketAtRevision.graphql");
/**
 * LastModifiedByCell
 * @param props
 * @returns
 */
const LastModifiedByCell = (props: GridCellProps) => {
  const item = props.dataItem;
  const displayName = [item.lastModifiedBy.lastName, item.lastModifiedBy.firstName]
    .filter(Boolean)
    .join(", ");
  return <td title={item.lastModifiedBy.id}>{displayName}</td>;
};

type RevisionsTicketMovementsProps = {
  ticketId: string;
  revisionNumber: string;
};

const childData = [
  { title: "Movement Id", field: "enterpriseSystemCode", key: 1 },
  {
    title: "Last Update Date",
    field: "lastModifiedDate",
    format: "{0:MM/dd/yy HH:mm:ss}",
    key: 2
  },
  {
    title: "Updated By",
    field: "lastModifiedBy",
    cell: LastModifiedByCell,
    key: 3
  }
];

/**
 *
 * @param param0
 * @returns
 */
const RevisionsTicketMovements = ({
  ticketId,
  revisionNumber
}: RevisionsTicketMovementsProps) => {
  const [revisions, setRevisions] = useState<TTicketMovementRevision[]>();

  const { loading, error } = useQuery<Response>(query, {
    fetchPolicy: "no-cache",
    variables: { ticketId, revisionNumber },
    onCompleted: data => setRevisions(transformData(data.movementsOfTicketAtRevision))
  });

  const transformData = (ticketRevisionMovements: TTicketMovementRevision[]) => {
    return ticketRevisionMovements?.map(trm => ({
      ...trm,
      lastModifiedDate: new Date(trm?.lastModifiedDate + "Z")
    }));
  };

  return (
    <RevisionsGridWrapper
      loading={loading}
      error={error}
      dataContent={revisions}
      columns={childData}
    />
  );
};

export default RevisionsTicketMovements;
