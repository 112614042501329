import { useQuery } from "@apollo/client";

import { GridCellProps } from "@progress/kendo-react-grid";

import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse, TVolumeAllocationsOfMovementAtRevision } from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

type Response = GqlResponse<
  TVolumeAllocationsOfMovementAtRevision[],
  "volumeAllocationsOfMovementAtRevision"
>;

const GET_AUDIT_ALLOCATIONS = loader(
  "../../ticketing-graphql/volumeAllocationsOfMovementAtRevision.graphql"
);

/**
 * LastModifiedByCell
 * @param props
 * @returns
 */
const LastModifiedByCell = (props: GridCellProps) => {
  const item = props.dataItem;
  const displayName = [item.lastModifiedBy.lastName, item.lastModifiedBy.firstName]
    .filter(Boolean)
    .join(", ");
  return <td title={item.lastModifiedBy.id}>{displayName}</td>;
};

const transformData = (volumeAllocations: TVolumeAllocationsOfMovementAtRevision[]) => {
  return volumeAllocations
    ?.map(v => ({
      ...v,
      groupId: v.groupId.split("-")[0],
      lastModifiedDate: new Date(v.lastModifiedDate + "Z"),
      targetEnterpriseSystemCode:
        v.srcEnterpriseSystemCode === v.targetEnterpriseSystemCode
          ? ""
          : v.targetEnterpriseSystemCode,
      volume: v.volume === 0 ? "" : v.volume
    }))
    .sort((a, b) => b.lastModifiedDate.getTime() - a.lastModifiedDate.getTime());
};

type RevisionsMovementAllocationsProps = {
  movementId: string;
  revisionNumber: string;
};

const columns = [
  {
    title: "Updated By",
    field: "lastModifiedBy",
    cell: LastModifiedByCell,
    key: 3
  },
  {
    title: "Updated At",
    field: "lastModifiedDate",
    format: "{0:MM/dd/yy HH:mm:ss}",
    key: 4
  },
  {
    title: "Source Delivery ID",
    field: "srcEnterpriseSystemCode",
    key: 5
  },
  {
    title: "Target Delivery ID",
    field: "targetEnterpriseSystemCode",
    key: 6
  },
  { title: "Allocation Quantity", field: "volume", key: 7 },
  { title: "Reason", field: "reason", key: 8 },
  { title: "Group ID", field: "groupId", key: 1 },
  { title: "Allocation ID", field: "id", key: 2 }
];

/**
 * RevisionsMovementAllocations
 * @param param0
 * @returns
 */
const RevisionsMovementAllocations = ({
  movementId,
  revisionNumber
}: RevisionsMovementAllocationsProps) => {
  const { loading, error } = useQuery<Response>(GET_AUDIT_ALLOCATIONS, {
    fetchPolicy: "no-cache",
    variables: { movementId, revisionNumber },
    onCompleted: data =>
      setVolumeAllocations(transformData(data.volumeAllocationsOfMovementAtRevision))
  });

  const [volumeAllocations, setVolumeAllocations] =
    useState<TVolumeAllocationsOfMovementAtRevision[]>();

  return (
    <RevisionsGridWrapper
      loading={loading}
      error={error}
      dataContent={volumeAllocations}
      columns={columns}
    />
  );
};

export default RevisionsMovementAllocations;
