import { ApolloError } from "@apollo/client";
import { toString as toKString } from "@progress/kendo-intl";
import { getter } from "@progress/kendo-react-common";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import {
  GridColumn as Column,
  Grid,
  GridColumnProps,
  GridNoRecords,
  GridPageChangeEvent
} from "@progress/kendo-react-grid";
import calculateSize from "calculate-size";
import { useCallback, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import LoadingPanel from "../../../shared/components/LoadingPanel";
// pass the font properties based on the application
const CalculateSizeOptions = {
  font: "Segoe UI",
  fontSize: "14px"
};

const PADDING = 16;

type RevisionsGridWrapperProps = {
  loading?: boolean;
  error?: ApolloError;
  dataContent?: unknown[];
  columns?: (GridColumnProps & { key?: number | string })[];
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };

const RevisionsGridWrapper = (props: RevisionsGridWrapperProps) => {
  const [page, setPage] = useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<number | string | undefined>();

  const calculateWidth = useCallback(
    (field?: string, title?: string, format?: string) => {
      let maxWidth = calculateSize(title ?? "", CalculateSizeOptions).width + PADDING;
      if (props?.dataContent && field) {
        props?.dataContent.forEach(item => {
          const valueGetter = getter(field);
          const value = valueGetter(item);
          const formattedValue =
            value instanceof Date && format ? toKString(value, format) : value;
          const size = calculateSize(formattedValue, CalculateSizeOptions).width + PADDING;
          if (size > maxWidth) {
            maxWidth = size;
          }
        });
      }
      return maxWidth;
    },
    [props?.dataContent]
  );

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.syntheticEvent as PagerTargetEvent;
    const take = event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }

    setPage({
      ...event.page,
      take
    });
  };
  return (
    <div className={"content-wrapper"} style={{ height: "100%" }}>
      <div className={"grid-wrapper"} style={{ height: "100%" }}>
        <div className={"card-container"} style={{ height: "100%" }}>
          {props?.loading && <LoadingPanel />}
          {props?.error && <ApolloErrorViewer error={props?.error} />}
          <Grid
            style={{ height: "100%" }}
            data={props?.dataContent?.slice(page.skip, page.take + page.skip)}
            resizable
            skip={page.skip}
            take={page.take}
            total={props?.dataContent?.length}
            pageable={{
              buttonCount: 4,
              pageSizes: [5, 10, 25, 50],
              pageSizeValue
            }}
            onPageChange={pageChange}
            dataItemKey="revisionNumber">
            <GridNoRecords>
              <div style={{ color: "gray" }}>There is no data available</div>
            </GridNoRecords>
            {props.columns?.map(column => (
              <Column
                {...column}
                key={column.key}
                headerClassName={"k-justify-content-center k-color-warning"}
                width={
                  column.width ?? calculateWidth(column.field, column.title, column.format)
                }
              />
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RevisionsGridWrapper;
