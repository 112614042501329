import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse, TMovementTicketRevision } from "ticketing/ticketing.types";
import { equalsIgnoreCase } from "ticketing/utils";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

type Response = GqlResponse<TMovementTicketRevision[], "ticketsOfMovementAtRevision">;
const GET_TICKET_REVISIONS_OF_MOVEMENT = loader(
  "../../ticketing-graphql/ticketsOfMovementAtRevision.graphql"
);

const transformData = (tickets: TMovementTicketRevision[]) => {
  return tickets
    ?.filter(t => !equalsIgnoreCase(t?.linkStatus, "UNLINKED"))
    .map(ticket => ({
      ...ticket,
      lastModifiedDate: new Date(ticket?.lastLinkModifiedDate + "Z")
    }))
    .sort((t1, t2) => t2.lastModifiedDate.getTime() - t1.lastModifiedDate.getTime());
};

type RevisionsMovementTicketsProps = {
  movementId: string;
  revisionNumber: string;
};

const RevisionsMovementTickets = ({
  movementId,
  revisionNumber
}: RevisionsMovementTicketsProps) => {
  const { loading, error } = useQuery<Response>(GET_TICKET_REVISIONS_OF_MOVEMENT, {
    fetchPolicy: "no-cache",
    variables: { movementId, revisionNumber },
    onCompleted: data => setTickets(transformData(data.ticketsOfMovementAtRevision))
  });
  const [tickets, setTickets] = useState<TMovementTicketRevision[]>([]);

  const childData = [
    { title: "MACk Id", field: "id", key: 1 },
    { title: "Ticket Number", field: "ticketNumber", key: 2 },
    { title: "Actualized Volume", field: "actualizedVolume", key: 3 },
    { title: "Updated By", field: "lastLinkModifiedBy", key: 4 },
    {
      title: "Updated At",
      field: "lastModifiedDate",
      format: "{0:MM/dd/yy HH:mm:ss}",
      key: 5
    }
  ];
  return (
    <RevisionsGridWrapper
      loading={loading}
      error={error}
      dataContent={tickets}
      columns={childData}
    />
  );
};

export default RevisionsMovementTickets;
