import { useQuery } from "@apollo/client";
import { Window } from "@progress/kendo-react-dialogs";
import { GridCellProps } from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse, TBatchRevision } from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

const lookupBatches = loader("../../ticketing-graphql/batchRevisions.graphql");

/**
 * LastModifiedByCell
 * @param props
 * @returns
 */
const LastModifiedByCell = (props: GridCellProps) => {
  const item = props.dataItem;
  const displayName = [item.lastModifiedBy.lastName, item.lastModifiedBy.firstName]
    .filter(Boolean)
    .join(", ");
  return <td title={item.lastModifiedBy.id}> {displayName}</td>;
};

/**
 * Transit Complete
 * @param props
 * @returns
 */
const TransitCompleteCell = (props: GridCellProps) => {
  const item: TBatchRevision = props.dataItem;
  const style = {
    backgroundColor: item.transitComplete ? "rgb(55, 180, 0,0.32)" : props.style?.color
  };
  return <td style={style}>{item.transitComplete ? "True" : "False"}</td>;
};

type Response = GqlResponse<TBatchRevision[], "batchRevisions">;

const transformData = (data: TBatchRevision[]): TBatchRevision[] => {
  return data
    .map(b => ({
      ...b,
      lastModifiedDate: new Date(`${b.lastModifiedDate}Z`)
    }))
    .sort((a, b) => b.revisionNumber.localeCompare(a.revisionNumber));
};

type BatchRevisionsProps = {
  batchId: string;
  onClose: () => void;
};
const childData = [
  { title: "Batch", field: "name", key: 1 },
  {
    title: "Revision Date/Time",
    field: "lastModifiedDate",
    format: "{0:MM/dd/yy HH:mm:ss}",
    key: 2
  },
  {
    title: "Updated By",
    field: "lastModifiedBy",
    cell: LastModifiedByCell,
    width: 200,
    key: 3
  },
  {
    title: "Transit Complete",
    field: "transitComplete",
    cell: TransitCompleteCell,
    key: 4
  }
];
/**
 *
 * @param param0
 * @returns
 */
const RevisionsBatch = ({ batchId, onClose }: BatchRevisionsProps) => {
  const { loading, error } = useQuery<Response>(lookupBatches, {
    fetchPolicy: "no-cache",
    variables: { id: batchId },
    onCompleted: data => setBatchRevisions(transformData(data.batchRevisions))
  });

  const [batchRevisions, setBatchRevisions] = useState<TBatchRevision[]>([]);

  return (
    <Window
      title={`Revisions of Batch: ${batchId}`}
      initialWidth={800}
      modal
      resizable
      onClose={onClose}>
      <RevisionsGridWrapper
        loading={loading}
        error={error}
        dataContent={batchRevisions}
        columns={childData}
      />
    </Window>
  );
};

export default RevisionsBatch;
