import { useQuery } from "@apollo/client";
import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, Window } from "@progress/kendo-react-dialogs";
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useMemo, useState } from "react";
import { GqlResponse, TMovementRevision } from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";
import RevisionsMovementAlerts from "./RevisionsMovementAlerts";
import RevisionsMovementAllocations from "./RevisionsMovementAllocations";
import RevisionsMovementDeliveryEvents from "./RevisionsMovementDeliveryEvents";
import RevisionsMovementMeasures from "./RevisionsMovementMeasures";
import RevisionsMovementTickets from "./RevisionsMovementTickets";

type Response = GqlResponse<TMovementRevision[], "movementRevisions">;

const GET_MOVEMENT_REVISIONS = loader("../../ticketing-graphql/movementRevisions.graphql");

type TMovementRevisionTransformed = Omit<
  TMovementRevision,
  | "actualizationComplete"
  | "lastModifiedBy"
  | "readyToInvoice"
  | "scheduledQuantity"
  | "actualizedQuantity"
> & {
  actualizationComplete: string;
  readyToInvoice: string;
  lastModifiedBy: string;
  scheduledQuantity: string;
  actualizedQuantity: string;
};

const transformData = (revisions: TMovementRevision[]) => {
  return revisions
    ?.map(rev => ({
      ...rev,
      startDate: new Date(`${rev?.startDate}Z`),
      revisionDateTime: new Date(`${rev?.revisionDateTime}Z`),
      actualizationComplete: rev?.actualizationComplete ? "Yes" : "No",
      readyToInvoice: rev?.actualizationComplete || rev?.readyToInvoice ? "Yes" : "No",
      actualizedQuantity: rev.actualizedQuantity
        ? `${toKString(rev.actualizedQuantity, "n4")} ${rev.unitOfMeasure?.name}`
        : "",
      scheduledQuantity: rev.scheduledQuantity
        ? `${toKString(rev.scheduledQuantity, "n4")} ${rev.unitOfMeasure?.name}`
        : "",
      lastModifiedBy: [rev.lastModifiedBy.lastName, rev.lastModifiedBy.firstName]
        .filter(Boolean)
        .join(", ")
    }))
    .sort((a, b) => b.revisionNumber.localeCompare(a.revisionNumber));
};

const getHyperLinkCell = (text: string, onClick: (revisionNumber: string) => void) => {
  return (props: GridCellProps) => (
    <td className="k-command-cell">
      <Button
        themeColor={"warning"}
        className="k-button theme-btn-yellow"
        onClick={() => onClick(props.dataItem?.revisionNumber)}>
        {text}
      </Button>
    </td>
  );
};

type MovementRevisionsProps = {
  movementId: string;
  enterpriseSystemCode: string;
  onClose: () => void;
};

const columns: (GridColumnProps & { key: number | string })[] = [
  { title: "Revision #", field: "revisionNumber", key: 0 },
  {
    title: "Movement Date",
    field: "startDate",
    format: "{0:MM/dd/yy}",
    key: 1
  },
  { title: "Status", field: "status.name", key: 2 },
  {
    title: "Product",
    field: "product.name",
    key: 3
  },
  {
    title: "Location",
    field: "titleTransferFacility.name",
    key: 4
  },
  {
    title: "Nom Activity",
    field: "activityType.name",
    key: 5
  },
  {
    title: "Scheduled Qty",
    field: "scheduledQuantity",
    key: 6
  },
  {
    title: "Actualized Qty",
    field: "actualizedQuantity",
    key: 7
  },
  {
    title: "Updated At",
    field: "revisionDateTime",
    format: "{0:MM/dd/yy HH:mm:ss}",
    key: 8
  },
  {
    title: "Updated By",
    field: "lastModifiedBy",
    key: 9
  },
  {
    title: "Act Complete",
    field: "actualizationComplete",
    key: 10
  },
  {
    title: "Ready To Invoice",
    field: "readyToInvoice",
    key: 11
  }
];

const RevisionsMovement = ({
  movementId,
  onClose,
  enterpriseSystemCode
}: MovementRevisionsProps) => {
  const { loading, error } = useQuery<Response>(GET_MOVEMENT_REVISIONS, {
    fetchPolicy: "no-cache",
    variables: { id: movementId },
    onCompleted: data => setTransformedData(transformData(data.movementRevisions))
  });

  const [transformedData, setTransformedData] = useState<TMovementRevisionTransformed[]>([]);

  const [showAllocations, setShowAllocations] = useState<boolean>(false);
  const [showTickets, setShowTickets] = useState<boolean>(false);
  const [showDeliveryEvents, setShowDeliveryEvents] = useState<boolean>(false);
  const [showMeasures, setShowMeasures] = useState<boolean>(false);
  const [showAlerts, setShowAlerts] = useState<boolean>(false);
  const [revisionNumber, setRevisionNumber] = useState<string>();

  const handleAllocation = (revNumber: string) => {
    setRevisionNumber(revNumber);
    setShowAllocations(true);
  };

  const handleTicket = (revNumber: string) => {
    setRevisionNumber(revNumber);
    setShowTickets(true);
  };

  const handleDeliveryEvents = (revNumber: string) => {
    setRevisionNumber(revNumber);
    setShowDeliveryEvents(true);
  };

  const handleAlert = (revNumber: string) => {
    setRevisionNumber(revNumber);
    setShowAlerts(true);
  };

  const handleMeasures = (revNumber: string) => {
    setRevisionNumber(revNumber);
    setShowMeasures(true);
  };

  const commandCellColumns = useMemo(
    () => [
      {
        cell: getHyperLinkCell("Allocations", handleAllocation),
        width: 90,
        key: 20
      },
      {
        cell: getHyperLinkCell("Tickets", handleTicket),
        width: 67,
        key: 21
      },
      {
        cell: getHyperLinkCell("Delivery Events", handleDeliveryEvents),
        width: 120,
        key: 22
      },
      {
        cell: getHyperLinkCell("Measures", handleMeasures),
        width: 84,
        key: 23
      },
      {
        cell: getHyperLinkCell("Alerts", handleAlert),
        width: 56,
        key: 24
      }
    ],
    []
  );
  const deliveryIdTitle = `Movement Revisions: Delivery ID - ${enterpriseSystemCode}`;

  return (
    <Window
      title={deliveryIdTitle}
      initialHeight={500}
      onClose={onClose}
      initialWidth={1000}
      resizable
      className="movement-revisions"
      modal>
      {showAllocations && revisionNumber && (
        <Dialog
          width={"1000px"}
          title={`Revision Allocations: ${deliveryIdTitle}`}
          onClose={() => setShowAllocations(false)}>
          <RevisionsMovementAllocations
            movementId={movementId}
            revisionNumber={revisionNumber}></RevisionsMovementAllocations>
        </Dialog>
      )}
      {showTickets && revisionNumber && (
        <Dialog
          title={`Revision Tickets: ${deliveryIdTitle}`}
          onClose={() => setShowTickets(false)}>
          <RevisionsMovementTickets
            movementId={movementId}
            revisionNumber={revisionNumber}></RevisionsMovementTickets>
        </Dialog>
      )}
      {showAlerts && revisionNumber && (
        <Dialog
          title={`Revision Alerts: ${deliveryIdTitle}`}
          onClose={() => setShowAlerts(false)}>
          <RevisionsMovementAlerts
            movementId={movementId}
            revisionNumber={revisionNumber}></RevisionsMovementAlerts>
        </Dialog>
      )}
      {showMeasures && revisionNumber && (
        <Dialog
          title={`Revision Measures: ${deliveryIdTitle}`}
          onClose={() => setShowMeasures(false)}>
          <RevisionsMovementMeasures
            movementId={movementId}
            revisionNumber={revisionNumber}></RevisionsMovementMeasures>
        </Dialog>
      )}
      {showDeliveryEvents && revisionNumber && (
        <Dialog
          title={`Revision DeliveryEvents: ${deliveryIdTitle}`}
          onClose={() => setShowDeliveryEvents(false)}>
          <RevisionsMovementDeliveryEvents
            movementId={movementId}
            revisionNumber={revisionNumber}></RevisionsMovementDeliveryEvents>
        </Dialog>
      )}

      <RevisionsGridWrapper
        loading={loading}
        error={error}
        dataContent={transformedData}
        columns={columns.concat(commandCellColumns)}
      />
    </Window>
  );
};

export default RevisionsMovement;
