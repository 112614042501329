import { useQuery } from "@apollo/client";
import { GridCellProps } from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useState } from "react";
import { GqlResponse, TTicketRailcarRevision } from "ticketing/ticketing.types";
import RevisionsGridWrapper from "./RevisionsGridWrapper";

type Response = GqlResponse<TTicketRailcarRevision[], "railcarsOfTicketAtRevision">;

const query = loader("../../ticketing-graphql/railcarsOfTicketAtRevision.graphql");

const LastModifiedByCell = (props: GridCellProps) => {
  const item = props.dataItem;
  const displayName = [item.lastModifiedBy.lastName, item.lastModifiedBy.firstName]
    .filter(Boolean)
    .join(", ");
  return <td title={item.lastModifiedBy.id}>{displayName}</td>;
};

const transformData = (ticketRevisionRailcars: TTicketRailcarRevision[]) => {
  return ticketRevisionRailcars?.map(trm => ({
    ...trm,
    lastModifiedDate: new Date(trm.lastModifiedDate + "Z")
  }));
};

type RevisionsTicketRailcarsProps = {
  ticketId: string;
  revisionNumber: string;
};

/***
 *
 */
const RevisionsTicketRailcars = ({
  ticketId,
  revisionNumber
}: RevisionsTicketRailcarsProps) => {
  const [revisions, setRevisions] = useState<TTicketRailcarRevision[]>();

  const { loading, error } = useQuery<Response>(query, {
    fetchPolicy: "no-cache",
    variables: { ticketId, revisionNumber },
    onCompleted: data => setRevisions(transformData(data.railcarsOfTicketAtRevision))
  });

  const childData = [
    { title: "Railcar #", field: "railcarNumber", width: "140px", key: 1 },
    {
      title: "Updated At",
      field: "lastModifiedDate",
      format: "{0:MM/dd/yy HH:mm:ss}",
      key: 2
    },
    {
      title: "Updated By",
      field: "lastUpdateUser",
      cell: LastModifiedByCell,
      key: 3
    }
  ];

  return (
    <RevisionsGridWrapper
      loading={loading}
      error={error}
      dataContent={revisions}
      columns={childData}
    />
  );
};

export default RevisionsTicketRailcars;
